@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: Inter !important;
  /* font-weight: bolder !important; */
}

.ml-20 {
  margin-left: 20px !important;
}


/* Variables */
:root {
  --theme-background: #265AA8;
}

/* Sidebar CSS */
.pro-sidebar>.pro-sidebar-inner {
  background-color: var(--theme-background) !important;
}

.pro-sidebar {
  min-width: 100% !important;
}

.pro-sidebar .pro-menu a {
  color: #fff !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
  white-space: break-spaces !important;
  overflow: inherit !important;
}


.pro-sidebar .pro-menu .pro-menu-item.active {
  background: linear-gradient(90deg, #255BA9 0%, #099EEB 48.41%, #265AA8 100%);
}

.submenu-head {
  color: white !important;
}

.submenu-head-active {
  background: linear-gradient(90deg, #255BA9 0%, #099EEB 48.41%, #265AA8 100%);
  color: white !important;
}

.submenu-item {
  margin: 10px 0px;
  /* border: 2px solid red; */

}

.submenu-item-active {
  color: white;
  font-weight: bolder;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  background: #265AA8 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: red;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 48px;
}

.table-row-font {
  font-size: 0.875rem !important;
  width: 100%;
}

/* For all Button (yellow bg) */
.yellow-bg {
  background-color: #F19E38 !important;
  color: white !important;
}

/* BreadCrumb style */
.breadcrumb-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #265AA8;
  cursor: pointer;
  text-transform: capitalize;
}

.fw-bold {
  font-weight: bold !important;
}

.MuiBreadcrumbs-separator {
  color: #265AA8;
  margin: 0px 5px !important;
}

.divider-color,
.MuiDivider-root {
  border-color: #D2D1D1 !important;
  margin: 30px 0px !important;
}

.title-typography {
  line-height: 32px !important;
  height: 32px !important;
  font-size: 21px !important;
  margin: 0px 0px 18px 0px !important;
  font-weight: 600px !important;
}

.page-heading {
  font-size: 34px !important;
  color: #265AA8;
  font-weight: 700 !important;
  /* margin-bottom: 16px !important; */
  /* font-family: 'Inter', sans-serif !important; */
  /* font-size: 34px !important;
      font-weight: 700 !important;
      color: #265AA8 !important;
      padding-left: 33px !important;
      text-transform: 'capitalize'; */
}

::placeholder {
  color: gray !important
}

.MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  top: 10px !important;
  right: 15px !important;
}

.disable-btn {
  background: #C9C9C9 !important;
  color: #FFFFFF !important;
}

/*To hide Mui datagrid vertical divider */
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
}

.css-biwvik-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

.css-1o0mzy7-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
}

/* Datagrid styles */
.MuiDataGrid-root {
  border: none !important;
}

.MuiDataGrid-main {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}

/* .MuiDataGrid-cell--withRenderer */
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
  /* max-width: auto !important;
  min-width: auto !important; */
  /* word-break: break-all; */
}

/* .MuiDataGrid-cell MuiDataGrid-cell--textLeft */

.MuiDataGrid-columnHeaderTitle {
  font-weight: 500 !important;
  color: #000000 !important;
  font-size: 16px !important;
}

.table-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table-icons-text {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table-height {
  height: 540px !important;
  position: relative !important;
}

.table-toolbar {
  display: flex !important;
  justify-content: flex-end !important;
  padding-bottom: 20px !important;
}

.css-4q101j-MuiDataGrid-root .MuiDataGrid-row {
  cursor: pointer;
}

.MuiDataGrid-row {
  padding: 5px 0px !important;
}

.MuiDataGrid-cellContent {
  text-overflow: unset !important;
  overflow-wrap: anywhere !important;
}

.remove-icon {
  display: flex;
  align-items: center;
  color: #265AA8;
  justify-content: flex-end;
}

.validation-text {
  color: red !important;
  font-size: 12px !important;
}

/* All details page Span */

.span-color {
  color: #000 !important;
  font-weight: 600 !important;
}

.menu-item-span {
  color: #9E9E9E !important;
}

.optional-span {
  font-size: 12px !important;
  color: #9E9E9E !important;
}

/* dashboard component */
.MuiPaper-root-MuiCard-root {
  padding: 0px !important;
  margin: 0px !important;
}

/* medioa Query */
.step-node-submit-btn {
  width: 89%;
  /* width: calc(100vw - 17%); */
  position: relative;
  height: calc(100vh - 115px);
}

.step-node-right {
  width: 89%;
  position: relative;
}

@media (min-width: 0px) and (max-width: 1024px) {
  .table-icons-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .step-node-submit-btn {
    /* width: '89%'; */
    width: calc(100vw - 18%);
    position: relative;
    height: calc(100vh - 115px);
  }

  .step-node-right {
    width: 82%;
    position: relative;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .step-node-submit-btn {
    /* width: '89%'; */
    width: calc(100vw - 13%);
    position: relative;
    height: calc(100vh - 115px);
  }

  .step-node-right {
    width: 87%;
    position: relative;
  }
}

.datepicker-btn-wrapper {
  display: flex !important;
  justify-content: space-around !important;
  padding-bottom: 17px !important;
}

.MuiPaper-root-MuiPickersPopper-paper {
  border-radius: 10px !important;
}

.datepicker-btn {
  padding: 9px 43px !important;
  border-radius: 10px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* Live classes */
.css-1x5jdmq {
  height: 48px !important;
  padding: 1px 14px !important;
}

.css-1q2h7u5.Mui-selected {
  color: #1976d2;
  font-weight: 500 !important;
}