/* Modal */

.modal-title{
  /* font-family: Inter !important; */
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 21px !important;
  line-height: 32px !important; 
  /* margin-bottom: 20px !important; */
  }
  .modal-heading{
    font-family: Inter !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  }